/*

(c) Copyright DOGI.DOG K9 Training, LLC 2022-Present. All rights reserved.

*/


/* tools */

class DogiModalHelper {
  constructor(modalObjs) {
    this.pages = new Array();
    this.initialize(modalObjs);
    this.reset();
  }

  initialize(modalObjs) {
    var inputs = modalObjs;
    var result = new Array();
    inputs.each(function (f) {
      result.push(inputs[f].id);
    });

    this.pages = result;
  }

  reset() {
    this.resetSpinner();
    $(".booknow_submit_btn").prop('disabled',false);
    $("#booking_error_response").text("");
    return this.current = 0;
  }

  resetSpinner() {
    $(".form_content").fadeIn();
    $(".modalcontrols").fadeIn();
    $("#booking_missing_fields").hide();
    $("#spinnercontainer").hide();
  }

  nextPage() {
    var next = this.current+1;
    if(next <= this.pages.length-1) {
      this.current++;
    }

    return this.pages[this.current];
  }

  prevPage() {
    var current = this.current;
    if(current > 0 && current <= this.pages.length-1) {
      this.current--;
    }

    return this.pages[this.current];
  }

  currentPage() {
    return this.pages[this.current];
  }

  startPage() {
    this.current = 0;
    return this.pages[0];
  }

  submitComplete() {
    var last = this.pages.length-1;
    this.current = last;
    return this.pages[last];
  }

  emptyRequired(rclass) {
    var required = $(rclass);
    var empty = [];
    required.each(function (f) {
      if(required[f].value == "") {
        var key = required[f].id;
        key = key.replace(/\_/, " ");
        key = key.replace(/\b\w/, (c) => c.toUpperCase());
        empty.push(key);
      }
    }); 

    if(empty.length == 0) {
      return false;
    }
    else {
      return empty;
    }
  }
}

class DogiSection {
    constructor(id,name=null,visibility=null,isLast=false) {
        this.id = id;
        this.name = name;
        this.visibility = visibility;
        this.isLast = isLast;
    }
}

class DogiPositioner { 
    constructor() {
      this.sections = Array();

      var s = this.getSectionIds('topcontainer').concat(this.getSectionIds('vert_container'));
      for(let i=0; i<s.length; i++) { 
        if(i == s.length-1) {
          var last = true;
        }
        else { var last = false; }
        this.addSection(s[i], this.getElementVisibility(s[i]), last);
      }
    }

    addSection(section,visibility,isLast) {
      this.sections.push(new DogiSection(this.sections.length, section, visibility, isLast));
    }

    getElementVisibility(s) {
      var r = document.getElementById(s).getBoundingClientRect();
      //console.log(r);
      if(Math.abs(r.y) <= r.height) {
        return 100 - Math.round(Math.abs(r.y)/r.height*100);
      }
      else {
        return false;
      }
    }

    getSectionIds(classname) {
      var r = Array();
      var a = document.getElementsByClassName(classname);
      for(let i=0; i<a.length; i++) {
        r.push(a[i].id);
      }
      return r;
    }

    visible() {
      var v = this.sections.filter(section => section.visibility != false);
      if(v[0].visibility == 100) {
        return [v[0]];
      }
      else {
        return v;
      }
    }

    next() {
      var v = this.visible();
      if(v.length > 1) {
        return v[1];
      }
      else {
        var target = v[0].id+1;
        if(target > this.sections.length) {
          return -1;
        }
        else {
          return this.sections[target];
        } 
      }
    }

    prev() {
      var v = this.visible();
      if(v.length > 1) {
        return v[0];
      }
      else {
        return this.sections[v[0].id-1];
     }
    }
}

/* Display navi when scrolling past menu button */

$(document).scroll(function () {
    let btnThreshold = $("#Home")[0].offsetHeight - $("#menu_button")[0].offsetHeight ; 

    if(document.body.scrollTop > btnThreshold || document.documentElement.scrollTop > btnThreshold) {
        if($("#navi")[0].style.display == "none" || $("#navi")[0].style.display == "" ) {
            $("#navi").fadeIn("fast");
            $("#callusnow").fadeIn("fast");
        }
    }
    else {
        if($("#navi")[0].style.display == "block") {
            $("#callusnow").fadeOut("fast");
            $("#navi").fadeOut("fast");
        }
    } 
});

/* effects and such */

$(window).on("load", function () {
    $("#logo").fadeIn(1000);
});


/* buttons */

$("#menu_button").click(function () {
    $("#menu_dropdown").slideToggle('fast');
});

$("#navNext").click(function () {
    try {
        p = new DogiPositioner();
        window.location.href = '/#' + p.next().name;
    }
    catch {
        //
    }
});

$("#navPrev").click(function () {
    try {
        p = new DogiPositioner();
        window.location.href = '/#' + p.prev().name;
    }
    catch {
        // 
    }
});

$("#navHome").click(function () {
    try {
        window.location.href = "/#";
    }
    catch {
        //
    }
});


/* Booking Modal wiring */

$(document).ready(function () {
  modalProperties = {
    fadeDuration: 250,
    showClose: true,
    closeExisting: true,
  };

  bookingHelper = new DogiModalHelper($(".bookingmodal"));
});

$("#feedback_btn").click(function () {
    $('#feedback_modal').modal(modalProperties);
    return false;
});

$("#booknow_btn, #booknow_btn_2").click(function () {
  bookingHelper.reset();
  $('#'+bookingHelper.startPage()).modal(modalProperties);
  return false;
});

$(".booknow_next_btn").click(function () {
  $("#" + bookingHelper.nextPage()).modal(modalProperties);
  return false;  
});

$(".booknow_prev_btn").click(function () {
  $("#" + bookingHelper.prevPage()).modal(modalProperties);
  return false;  
});

$(".booknow_close_btn").click(function () {
  $("#" + bookingHelper.currentPage()).modal('hide');
  $(".jquery-modal").fadeOut(modalProperties);
  return false;  
});

/* Swallow "feedback" */

$("#submitbutton").click(function() {
  $(".modalcontrols").fadeOut();
  $("#spinnercontainer").fadeIn();

  var inputs = $("#feedbackform :text, #feedbackform textarea, #feedbackform select");
  var data = {};
  inputs.each(function (f) {
    data[inputs[f].id] = inputs[f].value;
  });

  $.ajax({
    url: 'http://ec2-54-218-86-143.us-west-2.compute.amazonaws.com:5080/feedback',
    // url: 'http://localhost:8000/feedback',
    type: 'put',
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    },
    dataType: 'json',
    complete: function (data) {
      console.log($.parseJSON(data.responseText));
      // $("#" + bookingHelper.nextPage()).modal(modalProperties);
      // $(".modalcontrols").fadeIn();
    }
  });

  return false;
});


$(".booknow_submit_btn").click(function () {
  // check for empty req'd fields
  var empties = bookingHelper.emptyRequired(".required_field");
  if(Array.isArray(empties)) {
    $("#booking_missing_fields").text(empties.join(", ") + " are required fields.");
    $("#booking_missing_fields").show();
    return false;
  }
  else {
    $("#booking_missing_fields").hide();
  }

  $(".booknow_submit_btn").prop('disabled',true);
  $(".form_content").fadeOut();
  $(".modalcontrols").fadeOut();
  $("#spinnercontainer").fadeIn();

  var inputs = $(".bookingform :text, .bookingform textarea");
  var data = {};
  inputs.each(function (f) {
    data[inputs[f].id] = inputs[f].value;
  });

  $.ajax({
    url: 'http://ec2-54-218-86-143.us-west-2.compute.amazonaws.com:5080/consultation',
    // url: 'http://localhost:8000/consultation',
    type: 'put',
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    },
    dataType: 'json',
    success: function (data) {
      bookingHelper.resetSpinner();
      $("#" + bookingHelper.nextPage()).modal(modalProperties);
    },
    error: function (data) {
      $("#booking_error_response").text("Oops! Something went wrong. Please try again later.");
      bookingHelper.resetSpinner();
    }
  });

  return false;
});

$( function() {
  $("#desired_date").datepicker(
    {
      changeMonth: true,
      changeYear: true,
      dateFormat: 'mm/dd/yy',
      beforeShow: function (input, inst) {
          var rect = input.getBoundingClientRect();
          setTimeout(function () {
            inst.dpDiv.css({ top: rect.top + 20, left: rect.left + 0 });
          }, 0);
      }
    }
  );
});

/* Slideshow */ 

$(document).ready(function() {
  var slides = $('.slideshow_slide');
  var slideIndex = 0;
  
  $(slides[slideIndex]).css('display','flex');
  
  setInterval(function() {
    $(slides[slideIndex]).hide();
    slideIndex = (slideIndex + 1) % slides.length;
    //console.log(slideIndex);
    $(slides[slideIndex]).css('display','flex');
  }, 4000); 
});



// $("#submitbutton").click(function() {
//     let f = new FormData(document.getElementById('feedbackform'));
    
//     fetch("/feedback.php", {
//     method: 'POST',
//         body: f,
//     })
//     .then(foo => foo.text())
//     .then(response => {
//         $("#feedbackform")[0].reset();
//         $("#thankyou").text(response);
//         $("#thankyou").fadeIn("fast");
//         $("#submit").prop("disabled", true);
//     })

//     return false;
// });


